@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
@font-face {
  font-family: "SF PRO REGULAR";
  src: url("../fonts/SFProText-Regular.woff2") format("woff2"),
    url("../fonts/SFProText-Regular.woff") format("woff");
}
@font-face {
  font-family: "SF PRO MEDIUM";
  src: url("../fonts/SFProText-Medium.woff2") format("woff2"),
    url("../fonts/SFProText-Regular.woff") format("woff");
}
@font-face {
  font-family: "SF PRO SEMIBOLD";
  src: url("../fonts/SFProText-Semibold.woff2") format("woff2"),
    url("../fonts/SFProText-Semibold.woff") format("woff");
}
@font-face {
  font-family: "SF PRO BOLD";
  src: url("../fonts/SFProText-Bold.woff2") format("woff2"),
    url("../fonts/SFProText-Bold.woff") format("woff");
}
